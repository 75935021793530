* {
  box-sizing: border-box; 
}
img {
  display: block;
  width: 100%;
}

.mapboxgl-map {
  position: relative;
}

.mapboxgl-marker, .mapboxgl-popup {
  position: absolute;
  top: 0;
}

.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right {
  position: absolute;
  z-index: 2;
}
 
.mapboxgl-ctrl-top-right {
  top: 0;
  right: 0;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-custom-popup {
  background-color: white;
  display: block;
  cursor: pointer;
  padding: 5px;
}